import { ApplicationController } from './application_controller.js'
import { api } from '../components/AxiosHeaders.js'
import TomSelect from 'tom-select'
import Cookies from 'js-cookie'
import { htmlToElement } from '../components/Html.js'
import { queryString } from '../components/QueryString.js'

class ReloadTomSelect extends TomSelect {
  reloadSelect() {
    const newUrl = this.settings.firstUrl('')
    this.load('')
    this.setNextUrl('', newUrl)
  }
}

export default class extends ApplicationController {
  static values = {
    editGameUrl: String,
    showGameUrl: String,
    assignUrl: String,
    game: Number,
    returnToUrl: String,
    populateOnConnect: Boolean,
  }
  static targets = [
    'assignments', // the area that contains the assignments
    'select', // each individual assignment select box
    'official', // the detail area for a specific official
    'game', // the detail area for the game
    'icons', // detail area for conflict icons
    'actionButton', // all action buttons
    'form'
  ]
  get sortValues() {
    return ['name', 'distance']
  }

  get conflictValues() {
    return ['no', 'yes']
  }

  connect() {
    const controller = this
    console.log('assign controller connected')
    if (this.populateOnConnectValue) {
      console.log("populating on connect")
      this.populate()
    }
  }

  reload() {
    this.selectTargets.forEach((select) => {
      try {
        console.log('refreshing', select)
        select.tomselect.reloadSelect()
      } catch (error) {
        console.log('error refreshing select', select, error)
      }
    })
  }

  changeConflict(value) {
    this.setConflictValue(value)
    this.reload()
  }

  changeSort(value) {
    this.setSortValue(value)
    this.reload()
  }

  get hasUnsavedChanges() {
    return !!this.hasSelectTarget
  }
  get sortValue() {
    let sortCookie = Cookies.get('assign-sort')
    if (this.sortValues.includes(sortCookie)) {
      this.setSortValue(sortCookie)
      return sortCookie
    } else {
      this.setSortValue(this.sortValues[0])
      return this.sortValues[0]
    }
  }

  setSortValue(value) {
    Cookies.set('assign-sort', value)
    return value
  }

  changeConflicts(value) {
    this.setConflictValue(value)
    this.reload()
  }

  get conflictValue() {
    let conflictCookie = Cookies.get('assign-conflict')
    if (this.conflictValues.includes(conflictCookie)) {
      this.setConflictValue(conflictCookie)
      return conflictCookie
    } else {
      this.setConflictValue(this.conflictValues[0])
      return this.conflictValues[0]
    }
  }

  setConflictValue(value) {
    Cookies.set('assign-conflict', value)
    return value
  }

  async save() {
    const formData = new FormData(this.formTarget)
    console.log('data: ', formData)
    return api
      .put(`/assign/games/${this.gameValue}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Accept: 'application/json'
        }
      })
      .then((resp) => console.log('success!', resp))
      .then(() => this.cancel())
      .then(() => Promise.resolve(true))
      .catch((error) => {
        console.log('error!', error)
        if (error.response) {
          const { status, data, headers } = error.response
          console.log(error.response.data)
          console.log(error.response.status)
          console.log(error.response.headers)
          if (data?._embedded?.errors) {
            const message = data._embedded.errors
              .map((e) => e.message)
              .join(', ')
            alert(message)
            return Promise.reject(message)
          } else if (data.message) {
            alert(data.message)
            return Promise.reject(data.message)
          }
        } else {
          alert('An error occurred, your game could not be saved.')
          return Promise.reject(true)
        }
      })
  }

  async cancel() {
    const controller = this
    return api
      .get(`/assign/games/${this.gameValue}`)
      .then((resp) => {
        console.log('response is ', resp)
        const assignmentsElement = htmlToElement(resp.data)
        console.log('assignmentsElement', assignmentsElement)
        controller.assignmentsTarget.replaceChildren(assignmentsElement)
        let child = controller.officialTarget.lastElementChild
        while (child) {
          controller.officialTarget.removeChild(child)
          child = controller.officialTarget.lastElementChild
        }
        controller.assignmentsTarget.replaceChildren(assignmentsElement)
      })
      .then(() =>
        controller.actionButtonTargets.forEach((target) =>
          controller.showElement(target)
        )
      )
      .then(() => {
        let url = `/assign/games/${this.gameValue}/conflicts`;
        console.log(this.returnToUrlValue);
        if (this.returnToUrlValue) {
          url += `?return_to=${this.returnToUrlValue}`
        }
        return api.get(url);
      })
      .then((resp) => {
        console.log("conflict ind", resp.data)
        const conflictsElement = htmlToElement(resp.data)
        if (controller.hasIconsTarget) {
          console.log("iconsTarget", controller.iconsTarget)
          controller.iconsTarget.replaceChildren(conflictsElement)
        }
      })
      .then(() => Promise.resolve(true))
      .catch(() => Promise.reject(true))
  }

  async populate(event) {
    const { preassign } = (event && event.params) || {}
    const controller = this
    console.log('clicked populate')
    let firstOfficialPopulated = false

    return api
      .get(this.editGameUrlValue, { params: { preassign: preassign } })
      .then((resp) => {
        controller.actionButtonTargets.forEach((target) =>
          controller.hideElement(target)
        )
        console.log('response is ', resp)
        const assignmentsElement = htmlToElement(resp.data)
        console.log('assignmentsElement', assignmentsElement)
        if (controller.hasAssignmentsTarget) {
          controller.assignmentsTarget.replaceChildren(assignmentsElement)
        }
        this.selectTargets.forEach((select) => {
          const { positionId } = select.dataset
          const { assignmentId } = select.dataset

          const getUser = (userId) => {
            const controller = this;
            if (!userId || !controller.gameValue) {
              return
            }
            api
              .get(`/assign/games/${controller.gameValue}/users/${userId}`, {
                params: { position_id: positionId, assignment_id: assignmentId }
              })
              .then((resp) => {
                const userElement = htmlToElement(resp.data)
                if (controller.hasOfficialTarget) {
                  controller.officialTarget.replaceChildren(userElement)
                }
              })
              .catch((error) => console.error('error on getUser', error))
          }

          const ts = new ReloadTomSelect(select, {
            valueField: 'id',
            labelField: 'name',
            searchField: ['name'],
            maxOptions: null,
            loadThrottle: 500,
            placeholder: '« Unassigned »',
            plugins: ['clear_button', 'virtual_scroll'],
            preload: true,
            firstUrl: function (query) {
              const qs = queryString({
                page: 1,
                sort: controller.sortValue,
                hide_conflicts: controller.conflictValue,
                search: query
              })
              return `/assign/assignments/${assignmentId}.json?${qs}`
            },
            load: function (query, callback) {
              const url = this.getUrl(query) || this.firstUrl(query)
              console.log(`this.getUrl returns`, url)
              api
                .get(url)
                .then((resp) => {
                  const { pagination, users } = resp.data
                  if (pagination.next) {
                    console.log(
                      `more records available: pagination ${pagination.next}`
                    )
                    const qs = queryString({
                      page: pagination.next,
                      sort: controller.sortValue,
                      hide_conflicts: controller.conflictValue,
                      search: query
                    })

                    this.setNextUrl(
                      query,
                      `/assign/assignments/${assignmentId}.json?${qs}`
                    )
                  }
                  callback(users)
                })
                .catch((error) => {
                  console.log('error!', error)
                  callback()
                })
            },

            sortField: [{ field: '$order' }, { field: '$score' }],
            // sortField: [{ field: 'has_location' }, { field: 'km' },{ field: 'name' }],
            render: {
              // loading_more: (data,escape) => {
              //   return `<div class="loading-more-results"><i class="fa-regular fa-arrows-rotate"></i>Loading more results ... </div>`;
              // },
              option: (data, escape) => {
                const { conflicts, assigned, age, ussf, years, grade, request, active } = data
                // if (!(positionId && conflicts)) {
                //   console.log('data for undefined position', data)
                // } else {
                //   console.log('data for defined position', data)
                // }
                const thisAssignment = data.assignment == assignmentId
                if (thisAssignment) {
                  console.log(`assignment: ${assignmentId}: active: ${active}`, data)
                }
                let ageLicense = []
                if (age) {
                  ageLicense.push(`age ${age}`);
                }
                if (ussf) {
                  ageLicense.push(ussf);
                  if (years) {
                    if (years == 0) {
                      ageLicense.push('new');
                    }
                    else if (years == 1) {
                      ageLicense.push('1 year');
                    }
                    else {
                      ageLicense.push(`${years} years`);
                    }
                  }
                } else if (grade) {
                  ageLicense.push(grade);
                }

                const requestDisplay = request
                  ? `<i class="fa-solid fa-hand fa-color-success" title="Game Request"></i>`
                  : ''
                const ageDisplay =
                  ageLicense.length > 0 ? `(${ageLicense.join(', ')})` : ''
                const conflictIndicator = conflicts
                  ? `<span class='fa-color-danger'>${conflicts}</strong>`
                  : `<strong class='fa-color-success'>OK</strong>`
                const currentIcon = thisAssignment
                  ? `<i title='Assigned' class='fa-fw fa-solid fa-circle-right fa-color-primary'></i>`
                  : assigned
                    ? `<i title='Assigned to This Game' class='fa-fw fa-solid fa-circle-right fa-color-default'></i>`
                    : active
                      ? `<i class='fa-fw fa-regular fa-circle-user'></i>`
                      : `<i title="Inactive Account" class='fa-fw fa-solid fa-color-danger fa-user-xmark'></i>`
                return `<div style="border-bottom: 1px solid #ddd">
                <div style="display: flex; flex-flow: row wrap; justify-content: space-between; column-gap: 5px; align-items: center;">
                  <div style="flex: 1 0 0; display: flex; flex-flow: column; justify-content: space-between; column-gap: 5px;">
                    <!-- row one -->
                    <div style="display: flex; flex-flow: row wrap; justify-content: space-between; column-gap: 5px; align-items: center;">
                      <div style="">${currentIcon}</div>
                      <div style="flex: 1 0 0">
                        <strong>
                          ${escape(data.name)}
                        </strong>
                        ${ageDisplay}
                      </div>        
                    </div>
                    <!-- row two -->
                    <div style="display: flex; flex-flow: row wrap; justify-content: space-between; column-gap: 5px; align-items: center;">
                      ${data.working
                    ? `<i title="Current Location" class='fa-fw fa-solid fa-location-dot'></i>`
                    : `<i class='fa-fw fa-regular fa-house'></i>`
                  }
                      <div style="flex: 1 00 0">
                        ${escape(data.current)}
                        ${data.miles && data.miles > 0
                    ? `(${data.distance})`
                    : ''
                  }
                      </div>  
                    </div>
                  </div>
                  ${requestDisplay}
                  <div style="margin-right: 10px; ">
                    ${conflictIndicator}
                  </div>
                </div>
              </div>`
              },
              item: (data, escape) => {
                console.log('item: data', data)
                const { conflicts } = data
                const conflictIndicator = conflicts
                  ? `[${conflicts}]`
                  : `<strong class='fa-color-success'>OK</strong>`
                return `<div>
                          <div style="margin-left: 10px; display: flex; flex-flow: row wrap; column-gap: 5px; align-items: center">
                            <div style="flex: 6 0 0">
                              ${escape(data.name)}
                            </div>
                            <span>
                              ${conflictIndicator}
                            </span>
                          </div>
                        </div>`
              }
            }
          })
          ts.on('change', () => getUser(select.value))
          ts.on('focus', () => getUser(select.value))
          ts.on('blur', function () {
            console.log('blur')
            this.reloadSelect()
          })
          if (select.value && !firstOfficialPopulated) {
            firstOfficialPopulated = true
            getUser(select.value)
          }
        })
      })
      .then(() => Promise.resolve(true))
      .catch((error) => {
        console.log('error: ', error)
        return Promise.reject(true)
      })
  }

  reloadData() {
    this.populate({ params: { preassign: this.selectTargets.map(s => s.value).join(',') } });
  }
}
